import React, { useEffect, useState } from "react"
import Page from "../components/spark-express/page"
import "../assets/scss/typography.scss"
import "../assets/css/layout.css"
import Login from "../components/spark-express/login/container"

import useAuth from "../custom-hooks/auth"
import { getUserInformation } from "../api/spark-express/auth"
import { setUser } from "../services/auth"
import { navigate } from "gatsby"


if (typeof window !== 'undefined') {

  window.login = (token, id) => {
    getUserInformation(id, token)
      .then(response => response.json())
      .then(response => {
        setUser({ user: response })

        if (response.isCompletedDocuments)
          navigate('/deliveries')
        else
          navigate('/documents')
      })
  }
}


const Application = () => {

  useAuth();

  return (
    <Page>
      <Login />
    </Page>
  )
}

export default Application
