import React, { useState } from "react"
import LoginComponent from "./index"
import { signIn } from "../../../api/spark-express/auth"
import { setUser } from "../../../services/auth"
import { navigate } from "gatsby"
import { pingToReactNativeWebview } from "../../../services/react native webview"

const LoginContainer = () => {
  const [loading, setLoading] = useState(false)
  const signInAsRider = ({ email, password }) => {
    console.log(signIn)
    console.log("signing in")
    setLoading(true)
    signIn(email, password)
      .then((response) => {
        if (response.status === 200) {
          response.json().then((response) => {
            setUser(response, () => {
              if (response.user.isCompletedDocuments) {
                pingToReactNativeWebview("LOGIN", response)
                navigate("/deliveries")
              } else {
                navigate("/documents")
              }
            })
          })
          //navigate("/deliveries")
        } else {
          alert("Wrong credentials, please try again")
        }

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        alert("There is something happened, please try again")
        //error here
        setLoading(false)
      })
  }

  return <LoginComponent loading={loading} onSubmit={signInAsRider} />
}

export default LoginContainer
