//this file checks for auth status of the user


import { useEffect } from "react"
import { isLoggedIn, getUser } from "../services/auth"
import { navigate } from "gatsby"

import { getUserInformation } from "../api/spark-express/auth"
import { setUser } from "../services/auth"


const useAuth = () => {
  useEffect(() => {
    let mounted = true

    if (mounted) {
      if (isLoggedIn()) {
        //find user information
        getUserInformation(getUser().userId, getUser().token)
          .then((response) => response.json())
          .then((response) => {
            
            setUser({user: response})

            //if the rider completed his documents, proceed to receiving deliveries otherwise make him submit documents
            if (response.isCompletedDocuments) {
              navigate("/deliveries")
            } else {
              navigate("/documents")
            }
          })
      } else navigate("/")
    }

    return () => {
      mounted = false
    }
  }, [])
}

export default useAuth
